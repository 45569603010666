<template>
  <div class="cooperation">
    <div
      class="container-fluid typeStyle type-fa_wlxljy"
      :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-fa2_xqhz.jpg') + ')'
      }"
    >
      <div class="container">
        <div class="row moreDes-titBox">
          <h1 class="Tit">校企合作</h1>
          <p class="moreDes">
            针对学生毕业现状和市场岗位需求，提供职业素养、就业技能以及项目实训等多元化的校企合作综合解决方案，打通产学链条的“最后一公里”。助力院校凝练培养特色，构建复合型人才培养模式，让学生真正了解职业环境，强化实操技能，提升就业能力。
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid cont-nav">
      <div class="row cont-nav1">
        <div class="container">
          <div class="clearfix">
            <ul class="fl cont-navL">
              <li id="Anchor">
                <a class="cur">服务内容</a>
                <a class="">解决问题</a>
              </li>
            </ul>
            <div class="fr cont-navSel">
              <a >融合教育解决方案<i>&gt;</i></a>
              <div class="navSel">
                <a  class="vbn">校企合作</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row cont-nav2"></div>
    </div>
    <div class="clearfix TW-box" id="a1">
      <div class="container-fluid TW-Wrap bg-grey">
        <div class="container-fluid TitleWrap">
          <div class="container" style="padding:0">
          <div class="title">
            <h2 class="T">服务内容</h2>
            <i></i>
          </div>
          <div class="clearfix mCon imgL-list blo-white xq-list bgChange-list">
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_01"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">融合教育平台</div>
							<div class="imgL-con r3">基于AI和大数据等信息技术，集成智慧云课堂、第二课堂、双创、职业测评、就业等多个应用场景，提供一站式教学服务，包括预习、上课、随堂练习、课后作业和考试等</div>
						</div>
					</div>
				</div>
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_02"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">优质教学资源</div>
							<div class="imgL-con r3">出版60余本泛IT专业教材，涵盖1200多所大学，覆盖的大学100余万人次，教材总发行量120余万册</div>
						</div>
					</div>
				</div>
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_03"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">高校实训</div>
							<div class="imgL-con r3">通过毕业项目设计、就业@企业级项目实训，提高学生技术能力及综合职业素质</div>
						</div>
					</div>
				</div>
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_04"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">师资培训</div>
							<div class="imgL-con r3">解决教师学习新技术、落地实战项目、实现新课教学等需求，师资培训总人数近1000人</div>
						</div>
					</div>
				</div>
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_05"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">协同育人</div>
							<div class="imgL-con r3">帮助高校进行教学内容和课程体系改革、实训项目资源、师资培养、改善实习实践条件、创新创业改革、创新创业基金等方面的共同建设</div>
						</div>
					</div>
				</div>
				<div class="xs12 xm12 xb6">
					<div class="imgL-item blo-white-item">
						<div class="imgL-imgbox">
							<i class="xq_06"></i>
						</div>
						<div class="imgL-info">
							<div class="imgL-tit">专业共建</div>
							<div class="imgL-con r3">涵盖云计算、大数据、人工智能、区块链、前端、UI/UE设计、PHP、C/C++、H5+全栈、新媒体、电商、视觉设计、软件测试、网络安全+运维等热门专业</div>
						</div>
					</div>
				</div>
			</div>
      </div>
        </div>
      </div>
    </div>
    <div class="project information" style="background: #f0f2f5">
      <div class="title">
        <h2>解决问题</h2>
        <i></i>
      </div>
      <div class="container" style="padding-bottom:0">
        <div class="list">
          <div class="item">
            <div  class="qwe">
              <div class="imgSpan">
                <img
                    src="../../../../assets/img/children/card_bg01.jpg"
                  alt=""
                />
                <div class="imgR-tit">管理者</div>
                <p class="imgR-info">精准监控 高效管理</p>
              </div>
              <div class="blo-con">
                <p class="time">
                  统一资源和用户管理，利用大数据、云计算等先进技术，帮助高校发现教学管理潜在问题和价值规律，提高决策分析能力和促进管理创新。
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div  class="qwe">
              <div class="imgSpan">
                <img
                  src="../../../../assets/img/children/card_bg01.jpg"
                  alt=""
                />
                <div class="imgR-tit">教师</div>
                <p class="imgR-info">轻松备课 互动教学</p>
              </div>
              <div class="blo-con">
                <p class="time">
                  强大的10000+课程资源支撑，高效完成教学资源获取及教案设计，课堂轻松互动，内容渗透率高，利用大数据精准掌握每个学生学习情况。
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div  class="qwe">
              <div class="imgSpan">
                <img
                  src="../../../../assets/img/children/card_bg01.jpg"
                  alt=""
                />
                <div class="imgR-tit">学生</div>
                <p class="imgR-info">精准学习 轻松就业</p>
              </div>
              <div class="blo-con">
                <p class="time">
                  通过测评深度解析个人水平与企业用人标准的差异，并智能推荐课程，利用线上+线下的学习平台，学习更高高效，完善的就业服务让就业变得更简单。
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div  class="qwe">
              <div class="imgSpan">
                <img
                    src="../../../../assets/img/children/card_bg01.jpg"
                  alt=""
                />
                <div class="imgR-tit">企业</div>
                <p class="imgR-info">全面掌握 高效招聘</p>
              </div>
              <div class="blo-con">
                <p class="time">
                  轻松获取学生大学成长轨迹，大数据多维度对学生综合能力及技术水平进行剖析，实现人才和岗位的快速匹配，有效降低企业招聘的成本。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/header/children/cooperation.scss";
</style>
<style lang="scss">
@import "../../../../assets/css/header/children/index.scss";
</style>
